body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E8ECF2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GoogleSans';
  src: local('GoogleSans'), url(./fonts/GoogleSans-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'GoogleSansBold';
  font-weight: 900;
  src: local('GoogleSans'), url(./fonts/GoogleSans-Regular.ttf) format('woff');

}


/* Sider menu */
.ant-menu-item-selected{
  background-color: rgba(255, 255, 255, 0.175) !important;
}

/* End Sider menu */

/* Header */

/* Navbar */
.ant-breadcrumb-link{
  color: white!important;
}
/* End Navbar */

/* End Header */

/* Button */
/* :where(.css-dev-only-do-not-override-1kuana8).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color:  black;
} */