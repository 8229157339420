.numberIcon {
    background-image: url('../../../assets//images//position.svg');
    background-repeat: no-repeat;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-weight: bold;
    background-size: contain;
    align-items: center;
    line-height: 35px;
    font-size: large;
}