.layer-custom {
    background: transparent !important;
    color: white !important;
    border-color: transparent !important;
    border: none;
    box-shadow: none !important;
}

.layer-custom-large {
    background: transparent !important;
    color: yellow !important;
    border-color: transparent !important;
    border: none;
    box-shadow: none !important;
    font-size: 20px;
    opacity: 0.7 !important;
}

.water-flow {
    stroke-dasharray: 15 20;
    stroke-dashoffset: 20;
    animation: water 15s infinite linear;
}

@keyframes water {
    0% {
        stroke-dashoffset: 10;
    }
    100% {
        stroke-dasharray: 20 15;
        stroke-dashoffset: -100;
    }
}